<template>
	<div v-show="show" class="alertaCustomizado rounded">   
		<feather-icon class="iconeAlerta" icon="AlertCircleIcon" size="16"/>
		<span class="alertaCorTextoCustomizada"> 
			O cadastro desta empresa não possui todos os dados obrigatórios preenchidos. 
			Edite a empresa e preencha os dados faltantes para que a empresa esteja apta para aderir a uma campanha.
		</span>
		<feather-icon 
			class="iconeX" 
			icon="XIcon" 
			size="16"
			@click.prevent="fechaAlerta()"
			role="button"
		/>
	</div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";

export default {
	props: {
		show: {
			required: true,
			type: Boolean,
		},
		situacao: {
			type: String
		},
	},

	data(){
		return {
			companyUser: this.$can(actions.VISUALIZAR, subjects.EMPRESAS_AE),
		}
	},
	methods: {
		fechaAlerta() {
			this.$emit('update:fechaAlerta', true)
		}
	}
}
</script>

<style>
	.alertaCustomizado{
		background-color: #FFF3E7;
		padding: 10px;
		display:flex;
		align-items:center;
		margin-bottom: 15px;
	}
	.alertaCorTextoCustomizada{
		font-size: 14px;
		font-style: "Montserrat";
		font-weight: bold;
		color:#FF7C02;
	}
	.iconeAlerta{
		color:#FF7C02;
		margin-right: 10px;
	}
	.iconeX{
		color:#FF7C02;
		margin-left: auto;
	}
</style>
